import { AUTH_API } from "../axios";
import {
	AUTHENTICATE_USER,
	LOGIN_USER,
	GET_LOGGED_IN_USER,
	LOGOUT_USER,
} from "./types";

export const authenticateUser = (credentials) => async (dispatch) => {
	const { data } = await AUTH_API.post("/login", credentials, {
		withCredentials: true,
	});
	dispatch({ type: AUTHENTICATE_USER, payload: data });
};

export const loginUser = () => {
	return {
		type: LOGIN_USER,
	};
};

export const getLoggedInUser = () => async (dispatch) => {
	const { data } = await AUTH_API.get("/", { withCredentials: true });
	dispatch({ type: GET_LOGGED_IN_USER, payload: data });
};

export const logoutUser = () => async (dispatch) => {
	const { data } = await AUTH_API.post("/logout", {}, { withCredentials: true });
	dispatch({ type: LOGOUT_USER, payload: data });
};
