import React from "react";
import { useSelector } from "react-redux";
import { Route, Redirect } from "react-router-dom";

export default function LandingRedirect({
	component: Component,
	...otherProps
}) {
	const auth = useSelector((state) => state.auth);
	return (
		<Route
			{...otherProps}
			render={(props) => {
				if (!auth.isLoading) {
					if (!auth.isLoggedIn) {
						return <Component {...props} />;
					} else {
						// if the user is logged in, then redirect to each role's home screen
						const { role, uid, project_id } = auth.userInfo;
						let homeURL;
						switch (role) {
							case "Admin":
							case "Provider":
								homeURL = `/${role.toLowerCase()}/${project_id}`;
								break;
							case "Parent":
							case "Child":
								homeURL = `/participant/${project_id}/${role.toLowerCase()}/${uid}`;
								break;
							default:
						}
						return <Redirect to={{ pathname: homeURL }} />;
					}
				} else {
					return null;
				}
			}}
		/>
	);
}
