import React, { useEffect, useState, Suspense, lazy } from "react";
import { BrowserRouter, Route } from "react-router-dom";
import { useDispatch } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import "react-confirm-alert/src/react-confirm-alert.css";
import { useIdleTimer } from 'react-idle-timer';

import "../styles/main.scss";
import { getLoggedInUser, logoutUser } from "../actions";
import ProtectedRoute from "./ProtectedRoute";
import LandingRedirect from "./LandingRedirect";

const AdminHome = lazy(() => import("./Admin/AdminHome"));
const ProviderHome = lazy(() => import("./Provider/ProviderHome"));
const IndividualParticipant = lazy(() => import("./Provider/IndividualParticipant"));
const Landing = lazy(() => import("./Landing"));
const LandingParticipant = lazy(() => import("./LandingParticipant"));
const ParentLogin = lazy(() => import("./ParentLogin"));
const ChildLogin = lazy(() => import("./ChildLogin"));
const ProviderLogin = lazy(() => import("./ProviderLogin"));
const AdminLogin = lazy(() => import("./AdminLogin"));
const AddParticipant = lazy(() => import("./Admin/AddParticipant"));
const PreviousSurveys = lazy(() => import("./PreviousSurveys"));
const PreviousSurveySpecific = lazy(() => import("./PreviousSurveySpecific"));
const AddAdmin = lazy(() => import("./Admin/AddAdmin"));
const AddProvider = lazy(() => import("./Admin/AddProvider"));
const EditParticipantProfile = lazy(() => import("./Admin/EditParticipantProfile"));
const EnterChildSurvey = lazy(() => import("./Admin/EnterChildSurvey"));
const UploadParentSurvey = lazy(() => import("./Admin/UploadParentSurvey"));
const SendChildSurveyLink = lazy(() => import("./Admin/SendChildSurveyLink"));
const SentLinksList = lazy(() => import("./Admin/SentLinksList"));
const Notes = lazy(() => import ("./Notes"));
const Referrals = lazy(() => import("./Referrals"));
const Emails = lazy(() => import("./Emails"));
const Appointment = lazy(() => import("./Appointment"));
const ProviderManagement = lazy(() => import("./ProviderManagement"));
const ParticipantHome = lazy(() => import("./Participant/ParticipantHome"));
const ChangePassword = lazy(() => import("./ChangePassword"));
const ResetPassword = lazy(() => import("./ResetPassword"));
const CustomChangePassword = lazy(() => import("./CustomChangePassword"));

export default function App() {
	const dispatch = useDispatch();
	const timeout = 0.5 * 60 * 60 * 1000; // 30 minutes
	const [remaining, setRemaining] = useState(timeout);
	
	const handleOnIdle = () => {
		// if the user has not done anything on the page for 30 minutes, log them out
		if (getRemainingTime() === 0) {
			dispatch(logoutUser());
		}
	};
	const {
		reset,
		pause,
		resume,
		getRemainingTime,
		getLastActiveTime,
		getElapsedTime,
		isIdle,
		isLeader
	} = useIdleTimer({
		timeout,
		onIdle: handleOnIdle,
		crossTab: {
			emitOnAllTabs: true
		}
	});

	useEffect(() => {
		setRemaining(getRemainingTime());
		setInterval(() => {
		  setRemaining(getRemainingTime());
		}, 1000);
	}, []);

	// on page refresh, the redux auth state is restored from session if logged in
	useEffect(() => {
		// DEBUGGING for dev/prod env
		console.log("ENVIRONMENT:", process.env.NODE_ENV);
		dispatch(getLoggedInUser());
	}, []);

	return (
		<BrowserRouter>
			<ToastContainer
				position="top-center"
				autoClose={1000}
				hideProgressBar={true}
				closeButton={false}
				draggable={false}
			/>
			<Suspense fallback={<div></div>}>
				{/* LOGIN pages */}
				<LandingRedirect path="/" exact component={Landing} />
				<LandingRedirect
					path="/participant-login"
					exact
					component={LandingParticipant}
				/>
				<LandingRedirect path="/parent-login" exact component={ParentLogin} />
				<LandingRedirect path="/child-login" exact component={ChildLogin} />
				<LandingRedirect path="/provider-login" exact component={ProviderLogin} />
				<LandingRedirect path="/admin-login" exact component={AdminLogin} />
				<LandingRedirect
					path="/reset-password"
					exact
					component={ResetPassword}
				/>
				<LandingRedirect
					path="/change-password/:hash"
					exact
					component={CustomChangePassword}
				/>
				{/* Admin pages */}
				<ProtectedRoute
					path="/admin/:projectId"
					exact
					component={AdminHome}
					authorizedRole={["Admin"]}
				/>
				<ProtectedRoute
					path="/admin/:projectId/add-participant"
					exact
					component={AddParticipant}
					authorizedRole={["Admin"]}
				/>
				<ProtectedRoute
					path="/admin/:projectId/add-admin"
					exact
					component={AddAdmin}
					authorizedRole={["Admin"]}
				/>
				<ProtectedRoute
					path="/admin/:projectId/add-provider"
					exact
					component={AddProvider}
					authorizedRole={["Admin"]}
				/>
				<ProtectedRoute
					path="/admin/:projectId/:participantType/:participantId"
					exact
					component={EditParticipantProfile}
					authorizedRole={["Admin"]}
				/>
				<ProtectedRoute
					path="/admin/:projectId/upload-child-survey"
					exact
					component={EnterChildSurvey}
					authorizedRole={["Admin"]}
				/>
				<ProtectedRoute
					path="/admin/:projectId/upload-survey"
					exact
					component={UploadParentSurvey}
					authorizedRole={["Admin"]}
				/>
				<ProtectedRoute
					path="/admin/:projectId/:participantId/child-survey/send-link"
					exact
					component={SendChildSurveyLink}
					authorizedRole={["Admin"]}
				/>
				<ProtectedRoute
					path="/admin/:projectId/child-token-list"
					exact
					component={SentLinksList}
					authorizedRole={["Admin"]}
				/>
				{/* Provider pages */}
				<ProtectedRoute
					path="/provider/:projectId"
					exact
					component={ProviderHome}
					authorizedRole={["Provider"]}
				/>
				<ProtectedRoute
					path="/participant-detail/:projectId/:participantType/:participantId"
					exact
					component={IndividualParticipant}
					authorizedRole={["Admin", "Provider"]}
				/>
				{/* Admin and Provider pages */}
				<ProtectedRoute
					path="/notes/:projectId/:participantType/:participantId"
					exact
					component={Notes}
					authorizedRole={["Admin", "Provider"]}
				/>
				<ProtectedRoute
					path="/referrals/:projectId/:participantType/:participantId"
					exact
					component={Referrals}
					authorizedRole={["Admin", "Provider"]}
				/>
				<ProtectedRoute
					path="/emails/:projectId/:participantType/:participantId"
					exact
					component={Emails}
					authorizedRole={["Admin", "Provider"]}
				/>
				<ProtectedRoute
					path="/appointment/:projectId/:participantType/:participantId"
					exact
					component={Appointment}
					authorizedRole={["Admin", "Provider"]}
				/>
				<ProtectedRoute
					path="/provider-management/:projectId/:participantType/:participantId"
					exact
					component={ProviderManagement}
					authorizedRole={["Admin", "Provider"]}
				/>
				<ProtectedRoute
					path="/previous-survey-specific/:projectId/:participantType/:participantId/:surveyId"
					exact
					component={PreviousSurveySpecific}
					authorizedRole={["Admin", "Provider"]}
				/>
				{/* Participant pages */}
				<ProtectedRoute
					path="/participant/:projectId/:participantType/:participantId"
					exact
					component={ParticipantHome}
					authorizedRole={["Parent", "Child"]}
				/>
				{/* Render Unity build html page for child survey */}
				<Route path="/take-survey" exact render={() => {window.location.href="CHRIS_WEB_BUILD_DEV/index.html"}} />
				{/* Common Pages */}
				<ProtectedRoute
					path="/previous-survey-data/:projectId/:participantType/:participantId"
					exact
					component={PreviousSurveys}
					authorizedRole={["Admin", "Provider", "Parent", "Child"]}
				/>
				<ProtectedRoute
					path="/initial-login"
					exact
					component={ChangePassword}
					authorizedRole={["Admin", "Provider", "Parent", "Child"]}
				/>
			</Suspense>
		</BrowserRouter>
	);
}
