import axios from "axios";

const protocol = window.location.protocol;
const hostname = window.location.hostname;

export const AUTH_API = axios.create({
	baseURL: `${protocol}//${hostname}/api/auth`,
});

export const USER_API = axios.create({
	baseURL: `${protocol}//${hostname}/api/users`,
});

export const SURVEY_API = axios.create({
	baseURL: `${protocol}//${hostname}/api/survey`,
});

export const NOTE_API = axios.create({
	baseURL: `${protocol}//${hostname}/api/note`,
});

export const REFERRAL_API = axios.create({
	baseURL: `${protocol}//${hostname}/api/referral`,
});

export const APPT_API = axios.create({
	baseURL: `${protocol}//${hostname}/api/appointment`,
});

export const QUESTION_API = axios.create({
	baseURL: `${protocol}//${hostname}/api/question`,
});

export const DIALOGUE_API = axios.create({
	baseURL: `${protocol}//${hostname}/api/dialogue`,
});

export const EMAIL_API = axios.create({
	baseURL: `${protocol}//${hostname}/api/emails`,
});