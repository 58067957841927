import React from "react";
import { useSelector } from "react-redux";
import { Route, Redirect } from "react-router-dom";

export default function ProtectedRoute({
	authorizedRole,
	component: Component,
	...otherProps
}) {
	const auth = useSelector((state) => state.auth);
	return (
		<Route
			{...otherProps}
			render={(props) => {
				const {
					projectId,
					participantType,
					participantId,
				} = props.match.params;
				// check if the user is logged in, role matches authorized role, and projectId
				if (!auth.isLoading) {
					if (
						auth.isLoggedIn &&
						authorizedRole.includes(auth.userInfo.role) &&
						(!projectId || auth.userInfo.project_id === projectId)
					) {
						// if child or parent, match the url Id with their own id, because they are not supposed to see other users' data
						if (
							participantType &&
							participantId &&
							(auth.userInfo.role === "Parent" ||
								auth.userInfo.role === "Child") &&
							(participantType != auth.userInfo.role.toLowerCase() ||
								participantId != auth.userInfo.uid)
						) {
							return (
								<Redirect
									to={{ pathname: "/", state: { from: props.location } }}
								/>
							);
						}
						return <Component {...props} />;
					} else {
						return (
							<Redirect
								to={{ pathname: "/", state: { from: props.location } }}
							/>
						);
					}
				} else {
					return null;
				}
			}}
		/>
	);
}
